/* You can add global styles to this file, and also import other style files */
@import "styles/material-theme";
@import "../../_base-shared/styles/base-shared-styles";

body {
  overflow-x: hidden;
}

.text-inter {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
}

.text-24-inter {
  font-family: 'Inter' !important;
  font-size: 24px;
}

.text-12 {
  font-size: 12px;
  font-family: 'Poppins' !important;
}

.text-14 {
  font-size: 14px;
  font-family: 'Poppins' !important;
}

.text-14-inter {
  font-size: 14px;
  font-family: 'Inter' !important;
}

.text-16-poppins {
  font-size: 16px;
  font-family: 'Poppins' !important;
}

.text-20-poppins {
  font-size: 20px;
  font-family: 'Poppins' !important;
}

.text-22-poppins {
  font-size: 22px;
  font-family: 'Poppins' !important;
}

.text-13-poppins {
  font-size: 13px;
  font-family: 'Poppins' !important;
}

.text-bold-roboto{
  color: #0a6ebd;
  font-size: 14px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.mat-focused .mat-form-field-label {
  /*change color of label*/
  color:rgba(121,163,3) !important;
}

.mat-focused .mat-form-field-appearance-standard .mat-form-field-underline {
    border-bottom: 1px solid rgba(121,163,3) !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple{
  background-color: rgba(121,163,3) !important;
}

input{
  color: #666666 !important;
}
.mat-expansion-panel-header{
  height: auto !important;
  min-height: 48px;
}
.mat-content{
  overflow: visible !important;
}
.mat-icon{
  overflow: visible !important;
}
